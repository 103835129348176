import { Component, Input, OnInit, Output } from '@angular/core';
import { GlobalListComponent } from '../../../../../shared/global-list';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService, ApiService, PermissionService } from '../../../../../services';
import { MatDialog } from '@angular/material/dialog';
import { EventEmitter } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { config } from '../../../../../../config';
import * as moment from 'moment';
import { XeroService } from '../../../../../services/xero.service';
import { XeroSalesLedgerInvoicesResponse } from '../../../../../models/response';
import { XeroStatusEnums } from '../../../../../enums/xeroStatus.enum';
import filterOverDue from '../../../../../helpers/dueDateChecker';

@Component({
  selector: 'app-sales-ledger',
  templateUrl: './sales-ledger.component.html',
  styleUrls: ['./sales-ledger.component.scss']
})
export class SalesLedgerComponent extends GlobalListComponent implements OnInit {

  tableConfigAndProps = {};
  salesLedger = [];
  declare dataItems: XeroSalesLedgerInvoicesResponse[];
  dataSource = new MatTableDataSource();
  buttonHeaderProps: any;
  guardianId: any;
  @Output() outstandingBalance: EventEmitter<any> = new EventEmitter<any>()
  @Input() xeroContact: string;

  xeroInvoiceUrl: string = 'https://go.xero.com/app/invoicing/view/';

  
  inputData = {
    'imageColumn': 'profilePicture',
    'actionColumn': 'Actions',
    'expandColumn': 'expandable',
    'firstColumn': 'No.',
    'lastColumn': '',
    'roundedTable': false,
    'hasSwitch': false,
    'buttonEvent': "output",
    'hyperLink': true
  }
  constructor(protected router: Router, protected apiService: ApiService, protected _route: ActivatedRoute, protected alertService: AlertService, protected permissionsService: PermissionService, protected dialog: MatDialog, protected _xeroService: XeroService) { 
    super(router, apiService, alertService, permissionsService)
    this.actionButtons =
      [
        { buttonLabel: "View in Xero", buttonRoute: "", visibility: true },
      ]
    this.headerButtons = [
    ]
    // , 'Actions': 'Actions'
    // this.columnHeader = {
    //   'date': 'Date', 'type': 'Type', 'status': 'Status', 'credit': 'Credit', 'debit': 'Debit','outstanding': 'Outstanding','due': 'Due', 'Actions': 'Actions'
    // };
    this.columnHeader = {
      'invoiceNumber': 'Invoice Number', 'reference': 'Reference', 'issueDate': 'Issue Date', 'dueDate': 'Due Date', 'paid': 'Paid','due': 'Due', 'currency': 'Currency', 'status': 
      'Status', 'Actions':
       'Actions'
    };
    this.tableConfigAndProps = {
      ActionButtons: this.actionButtons,
      inputData: this.inputData, columnHeader: this.columnHeader, dataSource: this.dataSource,
    };

    this.buttonHeaderProps = {
      headingLabel: "Session ",
      ActionButtons: this.headerButtons,
      hasButton: true,
      hasHeading: true,
      labelMargin: '10px',
      textclass: 'text-bolder text-color',
      margin: '0px',
      hasFilters: true,
      searchConfig: { label: 'Search', key: 'branchId', value: '' },
      builtInFilters: { key: 'branchId', value: localStorage.getItem('branchId') }
    };
    let sub = this._route.params.subscribe(params =>
    {
      this.guardianId = params['id'];
    })

    if (this.guardianId != 'add')
    // {
    //   this.getSalesLedger();
    // }
    super.ngOnInit();
  }

  ngOnInit(): void {
    if (this.guardianId != 'add')
      {
        this.getSalesLedger();
      }
  }

  async getSalesLedger(){
    if(!this.xeroContact){
      return;  
    }
    const response = await this._xeroService.getGuardianSalesLedger(this.xeroContact)  
    this.dataItems = response.data.invoices;
    this.outstandingBalance.emit(response.data.outstandingBalance.toFixed(2));
    this.afterListResponse();
  }

  actionButtonOutput(event: any): void
  {
    if (event.item.buttonLabel === "View in Xero")
    {
      window.open(this.xeroInvoiceUrl + event.row.invoiceID, '_blank');
    }
  }


  afterListResponse(): void
  {
    this.dataItems.forEach(element =>
    {
      element['invoiceNumber'] = element.invoiceNumber
      element['issueDate'] = moment(element.date).format(config.cmsDateFormat);
      element['dueDate'] = moment(element.dueDate).format(config.cmsDateFormat);
      // element['paiDate'] = moment(element.date).format(config.cmsDateFormat);
      element['reference'] = element.reference
      element['status'] = XeroStatusEnums[element.status as keyof typeof XeroStatusEnums] || "--";
      element['paid'] = element.amountPaid.toFixed(2);
      element['due'] = 0 || element.amountDue.toFixed(2);
      element['currency'] = 0 || element.currencyCode;
      element['Actions'] = 'Actions'
      if(element.status === XeroStatusEnums.AUTHORISED && filterOverDue(element.dueDate)){
        element['shouldHighlight'] = true;
      }else{
        element['shouldHighlight'] = false;
      }

    
    });
 
    this.tableConfigAndProps = {
      ActionButtons: this.actionButtons,
      inputData: this.inputData,
      columnHeader: this.columnHeader,
      dataSource: new MatTableDataSource(this.dataItems),
      pagination: this.pagination,
      onlyDelete: false
    };
  }

}
