import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { XeroContact } from '../../../../models/models';

@Component({
  selector: 'app-confirm-delink-xero-contact-popup',
  templateUrl: './confirm-delink-xero-contact-popup.component.html',
  styleUrls: ['./confirm-delink-xero-contact-popup.component.scss']
})
export class ConfirmDelinkXeroContactPopup implements OnInit {

  contact: XeroContact;
  @Inject(MAT_DIALOG_DATA) public data: any

  constructor(
    protected dialog: MatDialog, 
    protected dialogRef: MatDialogRef<ConfirmDelinkXeroContactPopup>
  ) {
    this.contact = {
      name: this.data?.guardian?.name,
      email: this.data?.guardian?.email,
      address: this.data?.guardian?.address,
      contactNo: this.data?.guardian?.mobileNumber
    };
  }


  ngOnInit(): void {
    this.contact = {
      name: this.data?.guardian?.name,
      email: this.data?.guardian?.email,
      address: this.data?.guardian?.address,
      contactNo: this.data?.guardian?.mobileNumber
    };
  }

  onConfirm(){
    console.log('IN XERO NEW CONTACT POPUP')    
    this.dialogRef.close('confirmed')
  }

  onCancel(){
    console.log('IN XERO NEW CONTACT POPUP')
    this.dialogRef.close('cancelled');
  }


}
