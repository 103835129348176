<div>
    <div style="text-align: center; margin-bottom: 15px;">
          <span id="contact-title">Confirmation</span>
          <p style="margin-top: 15px; color: #555; font-size: 14px !important;">Are you sure you want to delink this contact? This action cannot be undone.</p>
    </div>
    <div class="btn-container">
          <button
          (click)="onCancel()"
          class="btn-cancel">
                No
          </button>
          <button  
                (click)="onConfirm()"
                class="btn-confirm">
                Yes
          </button>
    </div>
</div>
