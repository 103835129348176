import { Injectable } from '@angular/core';

import { LambdaService } from './lambda.service'
import { CheckXeroContactDto, checkXeroStatusDto, CreateXeroContactDto, Integration, MapXeroContactDto, toggleXeroDto, XeroContactDto, XeroCreditNoteStatusDto, XeroDelinkContactDto, XeroInvoiceStatusDto } from '../models/models';
import { CheckXeroContactExistResponse, ConsentUrlResponse, CreateXeroContactResponse, ServerlessResponse, ToggleXeroResponse, XeroCreditNoteStatusesResponse, XeroDelinkContactResponse, XeroInvoiceStatusesResponse, XeroSalesLedgerResponse } from '../models/response';
import { AlertService } from './alert.service'

@Injectable({
  providedIn: 'root'
})
export class XeroService {

  constructor(
    private _lambdaService: LambdaService,
    private _alertService: AlertService
  ) {}

  async toggleXeroService(selectedIntegration: Integration): Promise<boolean>{
    const headOfficeId = localStorage.getItem('headOfficeId')
    if(!headOfficeId){
      console.error('Head office id not found');
      return false;				
    }
    const requestPayload: toggleXeroDto = {
      enabled: !selectedIntegration.isEnabled,
      organizationId: parseInt(headOfficeId),
    }
    if(!selectedIntegration.isEnabled){
      const xeroResponse = await this.checkXeroEnabled(requestPayload);      
      
      if(xeroResponse.data.hasOwnProperty('isXeroEnabled')){
        return xeroResponse?.data?.isXeroEnabled!;
      }

      const getConsentUrlResponse = await this.getConsentUrl();
      
      const authResult:boolean = await this.xeroAuthHandler(getConsentUrlResponse?.data?.redirectUrl);
      if(authResult){
        this._alertService.alertSuccess('Xero Connected', 'Your Xero account has been successfully connected. You can now manage your data seamlessly').then(resp => {
          if(!resp){
            location.reload();
          }
        })
      }else{
        this._alertService.alertInfo('Error Occured', "Authentication attempt failed")
      }
      return authResult;

    }
    const xeroResponse = await this.checkXeroEnabled(requestPayload);
    return xeroResponse?.data?.isXeroEnabled!;
  }

  async checkXeroEnabled(requestPayload: toggleXeroDto): Promise<ServerlessResponse<ToggleXeroResponse>>{
    const targetLambdaUrl = '/xero-toggle';
    return await this._lambdaService.lambdaPost(targetLambdaUrl, requestPayload)    
  }

  async getXeroStatus(requestPayload: checkXeroStatusDto): Promise<ServerlessResponse<ToggleXeroResponse>>{
    const targetLambdaUrl = `/xero-status?organizationId=${requestPayload.organizationId}`;
    return await this._lambdaService.lambdaGet(targetLambdaUrl);
  }
  
  async getConsentUrl(): Promise<ServerlessResponse<ConsentUrlResponse>>{
    const targetLambdaUrl = '/authorize-xero';
    return await this._lambdaService.lambdaGet(targetLambdaUrl);
  }

  xeroAuthHandler(consentUrl: string): Promise<boolean> {
    return new Promise((resolve) => {
      const authWindow = window.open(consentUrl, '_blank', 'width=1000,height=700');

      const checkWindowClosed = setInterval(() => {
        if (authWindow?.closed) {
          clearInterval(checkWindowClosed); 
          console.log("Authentication window closed by the user.");
          resolve(false); 
        }
      }, 500);

      window.addEventListener('message', (event) => {
        if (event.origin !== window.location.origin) return;
     
        const { status, data } = event.data;        
        if (status === 'success') {
          if(data.success){
            console.log('Received Xero auth data:', data);
            localStorage.setItem('XeroAuthorizedToken', JSON.stringify(data));
            authWindow?.close();
            clearInterval(checkWindowClosed);
            resolve(true);
          }else{
            resolve(false);
          }
        } else if(status === 'failed') {
          resolve(false);
        }

      });
    });
  }

  async createNewXeroContact(requestPayload: CreateXeroContactDto): Promise<ServerlessResponse<CreateXeroContactResponse>>{
    const targetLambdaUrl = "/create-xero-contact";
    return await this._lambdaService.lambdaPost(targetLambdaUrl, requestPayload)
  }

  async getXeroContactsList(requestPayload?: any): Promise<ServerlessResponse<XeroContactDto[]>>{
    const targetLambdaUrl = `/get-xero-contacts?name=${requestPayload}`;
    return await this._lambdaService.lambdaGet(targetLambdaUrl)
  }

  async mapXeroContact(requestPayload: MapXeroContactDto):Promise<ServerlessResponse<CreateXeroContactResponse>> {
    const targetLambdaUrl = "/map-xero-contact";
    return await this._lambdaService.lambdaPost(targetLambdaUrl, requestPayload)
  }

  async getXeroInvoiceStatuses(requestPayload: XeroInvoiceStatusDto):Promise<ServerlessResponse<XeroInvoiceStatusesResponse[]>>{
    const targetLambdaUrl = "/get-invoices";
    return await this._lambdaService.lambdaPost(targetLambdaUrl, requestPayload)
  }

  async getGuardianSalesLedger(requestParam: string):Promise<ServerlessResponse<XeroSalesLedgerResponse>>{
    const targetLambdaUrl = `/get-ledger-summary?xeroContact=${requestParam}`;
    return await this._lambdaService.lambdaGet(targetLambdaUrl)
  }

  async delinkXeroContact(requestPayload: XeroDelinkContactDto) : Promise<ServerlessResponse<XeroDelinkContactResponse>>{
    const targetLambdaUrl = `/delink-xero-contact`;
    return await this._lambdaService.lambdaPost(targetLambdaUrl, requestPayload)
  }

  async checkXeroContactExists(requestParams: CheckXeroContactDto): Promise<ServerlessResponse<CheckXeroContactExistResponse>>{
    const targetLambdaUrl = `/get-xero-contacts?userName=${requestParams.contactName}`;
    return await this._lambdaService.lambdaGet(targetLambdaUrl)
  }

  async getXeroCreditStatuses(requestPayload: XeroCreditNoteStatusDto):Promise<ServerlessResponse<XeroCreditNoteStatusesResponse[]>>{
    const targetLambdaUrl = "/get-credits";
    return await this._lambdaService.lambdaPost(targetLambdaUrl, requestPayload)
  }


}
