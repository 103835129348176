import { Component, Inject, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Observable, of } from 'rxjs';
import {debounceTime, distinctUntilChanged,switchMap} from 'rxjs/operators';
import { XeroContactDto } from '../../../../models/models';
import { XeroService } from '../../../../services/xero.service';
import { AlertService, ApiService } from '../../../../services';
import { LocalStorageService } from '../../../../services/localStorage.service';


@Component({
  selector: 'app-link-with-existing-contact',
  templateUrl: './link-with-existing-contact.component.html',
  styleUrls: [
    './link-with-existing-contact.component.scss',
    '../../../shared-style.scss'
  ],
})

export class LinkWithExistingContactComponent implements OnInit {
  
  guardian: any;
  searchControl = new FormControl('');
  filteredOptions: Observable<XeroContactDto[]>;
  selectedOption: XeroContactDto  
  options: XeroContactDto[] = []; 
  @Inject(MAT_DIALOG_DATA) public data: any

  constructor(
    protected dialog: MatDialog, 
    protected dialogRef: MatDialogRef<LinkWithExistingContactComponent>,
    protected _xeroService: XeroService,
    protected _alertService: AlertService,
    protected _localStorageService: LocalStorageService,
    protected _apiService: ApiService,
  ) {}
  async ngOnInit(){
    this.guardian = this.data.guardian;
    this.filteredOptions = this.searchControl.valueChanges.pipe(
      debounceTime(300), 
      distinctUntilChanged(),
      switchMap(value => value ? this.fetchOptions(value) : of([]))
    );
  }

  displayFn(contact: XeroContactDto): string {
    return contact && contact.contactName ? contact.contactName : contact.name ? contact.name : '';
  }

  private async fetchOptions(query: string) {
    const response = await this._xeroService.getXeroContactsList(query);
    if(!response.success){
      this._alertService.alertError("Error", response.message);
    }
    this.options = response.data;
    return response.data;
  }

  async onOptionSelected(event: any) {
    if (event.option.value === null) {
      console.log("Create new clicked")
      return;
    }
    this.selectedOption = event.option.value;
    this.dialogRef.close({message: "map_contact", selectedOption: this.selectedOption})
  }

  onCreateNew(event: MouseEvent): void {
    event.stopPropagation();
    console.log('Create New button clicked!');
    this.dialogRef.close('new')
  }

  onSkip(){
    this.dialogRef.close('skipped');
  }
}
