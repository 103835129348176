import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { XeroContact } from '../../../../models/models';
import { FormGroup, FormControl, Validators, AbstractControl, ValidationErrors } from '@angular/forms';
import {  switchMap, map, catchError } from 'rxjs/operators'
import { from, Observable, of, timer} from 'rxjs';

import { XeroService } from '../../../../services/xero.service';


@Component({
  selector: 'app-create-new-xero-contact',
  templateUrl: './create-new-xero-contact.component.html',
  styleUrls: [
    './create-new-xero-contact.component.scss',
    '/src/app/views/shared-style.scss'
  ]
})
export class CreateNewXeroContactComponent implements OnInit {

  contact: XeroContact;
  hasXeroContact: boolean = false;
  @Inject(MAT_DIALOG_DATA) public data: any
  isAsyncValidating : boolean = false;
  
  contactForm : FormGroup = new FormGroup({
    contactName: new FormControl('', [
      Validators.required,
    ],
  ),
    name: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required]),
    address: new FormControl('', [Validators.required]),
    contactNo: new FormControl('', [Validators.required])
  });

  constructor(
    protected dialog: MatDialog, 
    protected dialogRef: MatDialogRef<CreateNewXeroContactComponent>,
    protected _xeroService: XeroService,
  ) {}


  ngOnInit(): void {
    this.contact = {
      name: this.data?.guardian?.name,
      email: this.data?.guardian?.email,
      address: this.data?.guardian?.address,
      contactNo: this.data?.guardian?.mobileNumber
    };
    if(this.data?.selectedXeroContact){
      this.hasXeroContact = true
    }
    this.populateForm();
  }

  populateForm(){
    if(this.hasXeroContact){
      this.contactForm.patchValue({
        contactName: this.data?.selectedXeroContact.name,
        name: this.data?.guardian?.name,
        email: this.data?.guardian?.email,
        address: this.data?.guardian?.address,
        contactNo: this.data?.guardian?.mobileNumber
      });
    }else{
      this.contactForm.patchValue({
        contactName: this.data?.guardian?.name,
        name: this.data?.guardian?.name,
        email: this.data?.guardian?.email,
        address: this.data?.guardian?.address,
        contactNo: this.data?.guardian?.mobileNumber
      });

      // add custom validator on contact name 
      this.contactForm.get('contactName')?.setAsyncValidators(this.contactExists.bind(this));
    }

  }

  onSubmit(){
    if(this.hasXeroContact){
      this.confirmAndClose();
      return;
    }
    this.createNewContact();
  }
  
  /*
  * This function is called when this component is used as a dialog for creating a new xero contact
  */
  createNewContact(){
    if(this.contactForm.invalid){
      return;
    }
    this.dialogRef.close({
      isConfirmed: true,
      data: this.contactForm.value
    });
  }

  /*
  * This function is called when this component is used as a dialog for linking a guardian to an existing xero contact
  */
  confirmAndClose(){
    this.dialogRef.close('confirmed');  
  }

  onCancel(){
    console.log('IN XERO NEW CONTACT POPUP')
    this.dialogRef.close('cancelled');
  }

  contactExists(control: AbstractControl): Observable<ValidationErrors | null> {
    if (!control.value) {
      return of(null); 
    }
    this.isAsyncValidating = true;

    return timer(1000).pipe(
      switchMap(() => this._xeroService.checkXeroContactExists({ contactName: control.value })),
      map((resp) => {
        if (resp.data.exist) {
          this.isAsyncValidating = false;
          return { contactExists: true };
        }
        this.isAsyncValidating = false;
        return null; 
      }),
      catchError(() => of(null)))
  }

}
